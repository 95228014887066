import dayjs from 'dayjs'
import type { DocumentContext } from 'next/document'

import { combineWrappers } from './../utils/combineWrappers'
import { getClubConfig } from '~/utils/clubConfig'
import { globalBoostrapPropsWrapper, globalBootstrap, GlobalBootstrapWrapper } from '~/utils/globalBootstrap'

import Index from '~/components/Home/HomePage'
import { getHomePageData, MAIN_PAGE_TOP_COLLECTIONS_SLUG } from '~/components/Home/HomePage/HomePageUtils'
import { LoginModalWrapper } from '~/components/User/LoginModal'
import { SetNewPasswordWrapper } from '~/components/User/SetNewPasswordModal/SetNewPasswordModalView'
import { HOME_HERO_SHORTCUTS, INITIAL_NUMBER_OF_ATTENDEES } from '~/constants'
import { HomepageQueryDocument, HomepageQueryQuery, SelectedRegionDocument, SelectedRegionQuery } from '~/generated/graphql'

export const getServerSideProps = async (ctx: DocumentContext) => {
  const clubConfig = getClubConfig()

  if (clubConfig.redirectHeroToLandingEnabled) {
    return {
      redirect: {
        destination: '/restaurantweek',
        permanent: true,
      },
    }
  }

  const { apolloClient } = await globalBootstrap({ ctx })
  const { selectedRegion } =
    apolloClient.cache.readQuery<SelectedRegionQuery>({
      query: SelectedRegionDocument,
    }) || {}
  const { data } = await apolloClient.query<HomepageQueryQuery>({
    query: HomepageQueryDocument,
    variables: {
      region_id: selectedRegion?.id,
      section_slug: MAIN_PAGE_TOP_COLLECTIONS_SLUG,
    },
  })

  const { collections } = getHomePageData(data)

  return globalBoostrapPropsWrapper(
    { apolloClient },
    {
      props: {
        collections,
        search: {
          minDateISOString: dayjs().toISOString(),
          defaultPeopleCount: INITIAL_NUMBER_OF_ATTENDEES,
        },
        /* TODO: loading shortcuts from graphql (blocking: strapi) */
        shortcuts: HOME_HERO_SHORTCUTS,
      },
    }
  )
}

export default combineWrappers([LoginModalWrapper, SetNewPasswordWrapper, GlobalBootstrapWrapper], Index)
